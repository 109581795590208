import React from "react";
import { colors } from "../../lib/constant";
import styled from "styled-components";
import { fontFamily, product_sans } from "../../lib/fonts";

interface LinkProps {
  fontsize?: string;
}

const Container = styled.div<LinkProps>`
  color: ${colors.primary};
  transition: color 0.2s, background 0.2s;
  font-size: ${(props) => props.fontsize};
  ${fontFamily(product_sans.medium)};
  cursor: pointer;
  display: inline-block;
  line-height: 15px;

  &:hover {
    color: #fff;
    background: ${colors.primary};
  }
`;

export const DottedLink = styled(
  ({
    children,
    fontsize,
    className,
  }: {
    children: React.ReactNode;
    fontsize?: string;
    className?: string;
  }) => (
    <Container className={className} fontsize={fontsize}>
      {children}
    </Container>
  ),
)``;
