import React, { ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../../lib/constant";
import { IconArrowR16 } from "../../icons-ts";
import { fontFamily, fontSize, product_sans } from "../../lib/fonts";

interface Props {
  justifyContent: string;
  marginTop?: string;
}

const ArrowInTable = styled(IconArrowR16)`
  fill: ${colors.primary};
  margin-right: 10px;
  transition: margin 0.2s;
`;

const Container = styled.div<Props>`
  display: flex;
  justify-content: ${(p) => p.justifyContent};
  align-items: center;
  color: ${colors.primary};
  ${fontFamily(product_sans.medium)};
  transition: filter 0.2s;
  cursor: pointer;
  margin-top: ${(p) => p.marginTop || ""};
  ${fontSize("15px")}

  &:hover {
    filter: brightness(0.8);
  }

  &:hover ${ArrowInTable} {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const ArrowLink = ({
  children,
  justifyContent,
  marginTop,
}: {
  children: ReactNode;
  justifyContent: string;
  marginTop?: string;
}) => (
  <Container justifyContent={justifyContent} marginTop={marginTop}>
    <ArrowInTable width="16" />
    {children}
  </Container>
);
