import React from "react";
import { Layout } from "./ui/Layout";
import styled from "styled-components";
import { Button } from "./ui/Button";
import { Title } from "./ui/Title";
import { Paragraph } from "./ui/Paragraph";
import { FormattedMessage } from "react-intl";
import { colors } from "../lib/constant";
import { IconArrowR16 } from "../icons-ts";
import { mediaQuery } from "react-styled-mediaquery";

const bg = require("../../assets/bg-contact.svg");
const bubbleEmoji = require("../../assets/bubble-emoji.png");
const bubbleHearth = require("../../assets/bubble-hearth.png");

const Container = styled.div`
  background: url(${bg}) no-repeat center;
  padding: 160px 0 165px 0;

  ${mediaQuery("<=", "tablet")`
    padding: 80px 0 80px 0;
  `}

  ${mediaQuery("<=", "tablet")`
    padding: 40px 0 40px 0;
  `}
`;

const Card = styled.div`
  text-align: center;
  border-radius: 10px;
  padding: 60px 270px;
  background: ${colors.primaryLter};
  position: relative;

  ${mediaQuery("<=", "tablet")`
     padding: 30px 135px;
  `}

  ${mediaQuery("<=", "510px")`
     padding: 40px;
  `}
`;

const BubbleEmoji = styled.img`
  top: -10px;
  right: 120px;
  position: absolute;
  ${mediaQuery("<=", "tablet")`
    right: 0px;
  `}

  ${mediaQuery("<=", "510px")`
     display: none;
  `}
`;

const BubbleHearth = styled.img`
  bottom: -55px;
  left: 120px;
  position: absolute;
  ${mediaQuery("<=", "tablet")`
    left: 0px;
  `}

  ${mediaQuery("<=", "510px")`
     display: none;
  `}
`;

export const SectionContact = () => (
  <Layout id="contact">
    <Container>
      <Card>
        <BubbleEmoji src={bubbleEmoji} width="108" height="109" />
        <BubbleHearth src={bubbleHearth} width="108" height="109" />
        <Title htmlLevel={2} theme="titleBodyStyle">
          <FormattedMessage
            id="contact.title"
            defaultMessage="Interested or convinced ?"
          />
        </Title>

        <Paragraph style={{ marginBottom: 30 }}>
          <FormattedMessage
            id="contact.desc"
            defaultMessage="Contact us to obtain a free demo or for any information regarding Deal Platform."
          />
        </Paragraph>

        <a href="mailto:info@igloo.be?subject=Deal platform">
          <Button secondary icon={<IconArrowR16 width="16" fill="#fff" />}>
            <FormattedMessage
              id="contact.buttonLabel"
              defaultMessage="Contact us"
            />
          </Button>
        </a>
      </Card>
    </Container>
  </Layout>
);
