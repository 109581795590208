import styled from "styled-components";
import { colors } from "../../lib/constant";
import { fontFamily, product_sans } from "../../lib/fonts";

interface ParagraphProps {
  size?: string;
  lineHeight?: string;
}

export const Paragraph = styled.div<ParagraphProps>`
  color: ${colors.greyDrk};
  ${fontFamily(product_sans.regular)};
  font-size: ${(props) => props.size || `15px`};
  line-height: ${(props) => props.lineHeight || `25px`};
`;
