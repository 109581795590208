import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 1rem 0 1rem;
`;

export const Layout = React.forwardRef(
  ({ ...props }: {} & React.ComponentProps<typeof Wrapper>, ref) => (
    <Wrapper ref={ref} {...props} />
  ),
);
