import React from "react";
import styled from "styled-components";
import { colors } from "../../lib/constant";
import { Title } from "./Title";
import { fontFamily, fontSize, product_sans } from "../../lib/fonts";
import { mediaQuery } from "react-styled-mediaquery";
import { IconScreen50 } from "../../icons-ts";
import { FormattedMessage } from "react-intl";
import { Layout } from "./Layout";

const Container = styled.div`
  width: 100%;
  background: ${colors.primaryLter};
  margin: 0;
  border-radius: 10px;
  padding: 60px;
  box-sizing: border-box;
  margin: 75px 0 150px 0;

  ${mediaQuery("<=", "tablet")`
    margin-bottom: 50px ;
    margin-top: 0;
  `}

  ${mediaQuery("<=", "819px")`
     padding: 30px;
  `}

  ${mediaQuery("<=", "phablet")`
    padding: 25px;
  `}
`;

const Desc = styled.div`
  margin: 0 auto;
  margin-top: 15px;
  color: ${colors.secondary};
  max-width: 75%;
  text-align: center;
  line-height: 25px;
  max-width: 510px;
`;

const TitleCard = styled(Title)`
  ${fontSize("30px")}
  line-height: 37px;
  ${fontFamily(product_sans.medium)};
  margin-bottom: 0;
  text-align: center;
  color: ${colors.primary};

  ${mediaQuery("<=", "tablet")`
    ${fontSize("24px")}
    line-height: 27px;
  `}
`;

const Shape = styled(IconScreen50)`
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
`;

export const CardDevices = () => (
  <Layout>
    <Container id="download">
      <Shape width="50" fill={colors.primary} />
      <TitleCard htmlLevel={2}>
        <FormattedMessage
          id="cardRwd.title"
          defaultMessage="Ready for all type of screens"
        />
      </TitleCard>
      <Desc>
        <FormattedMessage
          id="cardRwd.desc"
          defaultMessage="Deal Platform is fully responsive and is ready to work from the smallest smartphone to the largest desktop screens."
        />
      </Desc>
    </Container>
  </Layout>
);
