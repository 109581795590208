import React from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { IconSeparator80 } from "../../icons-ts";

const Container = styled.div`
  text-align: center;
  margin: 150px 0;

  ${mediaQuery("<=", "tablet")`
    margin: 50px 0;
  `}
`;

export const Separator = ({ id }: { id?: string }) => (
  <Container id={id}>
    <IconSeparator80 width="80" />
  </Container>
);
