import React from "react";
import styled from "styled-components";
import { Layout } from "./ui/Layout";
import { mediaQuery } from "react-styled-mediaquery";
import { Title } from "./ui/Title";
import { Paragraph } from "./ui/Paragraph";
import { Col, Row } from "react-styled-flexboxgrid";
import { colors } from "../lib/constant";
import { IconCheck10 } from "../icons-ts";
import { fontFamily, fontSize, product_sans } from "../lib/fonts";
import { Button } from "./ui/Button";
import { FormattedMessage } from "react-intl";

const SectionPriceTitle = styled.div`
  text-align: center;
  width: 70%;
  margin: 0 auto 80px auto;

  ${mediaQuery("<=", "tablet")`
    width: 100%;
    margin-bottom: 30px;
  `}
`;

const Item = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 25px;
  }
`;

const Icon = styled(IconCheck10)`
  margin-right: 15px;
`;

const Label = styled.div`
  color: ${colors.greyDrk};
  ${fontSize("15px")};
  line-height: 18px;
  ${fontFamily(product_sans.regular)}
  display: flex;
  align-items: center;
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(27, 22, 51, 0.05);
  border-radius: 15px;
  padding: 60px;

  ${mediaQuery("<=", "tablet")`
    padding: 30px;
    margin-top: 50px;
    text-align: center;
  `}
`;

const Price = styled.div`
  color: ${colors.primary};
  ${fontSize("48px")};
  line-height: 40px;
  ${fontFamily(product_sans.bold)}

  > span {
    top: -18px;
    left: -10px;
    position: relative;
    ${fontSize("20px")};
  }
`;

const Month = styled.div`
  ${fontSize("15px")};
  line-height: 18px;
  ${fontFamily(product_sans.regular)}
  margin-top: 5px;
  margin-bottom: 30px;
`;

const Setup = styled.div`
  ${fontSize("13px")};
  line-height: 15px;
  ${fontFamily(product_sans.bold)}
  margin-bottom: 5px;
`;

const Desc = styled.div`
  ${fontSize("13px")};
  line-height: 20px;
  ${fontFamily(product_sans.regular)}
  margin-bottom: 30px;
`;

const TitlePrice = styled.div`
  ${fontSize("18px")};
  line-height: 22px;
  ${fontFamily(product_sans.bold)}
  margin-bottom: 30px;

  ${mediaQuery("<=", "tablet")`
    margin-top: 20px;
    text-align: center;
  `}
`;

export const SectionPrice = () => (
  <Layout id="price">
    <SectionPriceTitle>
      <Title htmlLevel={2} theme="titleHeaderStyle">
        <FormattedMessage
          id="sectionprice.title"
          defaultMessage="Simply & transparent pricing"
        />
      </Title>
      <Paragraph>
        <FormattedMessage
          id="sectionprice.desc"
          defaultMessage="Deal platform comes in one very simple formula: All inclusive ! No
          setup, no volume fees, just shoot your campaigns and don't worry about
          anything else !"
        />
      </Paragraph>
    </SectionPriceTitle>

    <Row middle="xs">
      <Col xs={12} sm={7} md={7} lg={7}>
        <Row>
          <Col xs={12}>
            <TitlePrice>
              <FormattedMessage
                id="sectionprice.featureTitle"
                defaultMessage="All inclusive"
              />
            </TitlePrice>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Item>
              <div>
                <Icon width="10" fill={colors.green} />
              </div>
              <Label>
                <FormattedMessage
                  id="sectionprice.feat1"
                  defaultMessage="Licence fee"
                />
              </Label>
            </Item>
            <Item>
              <div>
                <Icon width="10" fill={colors.green} />
              </div>
              <Label>
                <FormattedMessage
                  id="sectionprice.feat2"
                  defaultMessage="Encrypted exchange of data"
                />
              </Label>
            </Item>
            <Item>
              <div>
                <Icon width="10" fill={colors.green} />
              </div>
              <Label>
                <FormattedMessage
                  id="sectionprice.feat3"
                  defaultMessage="Unlimited devices/downloads"
                />
              </Label>
            </Item>
            <Item>
              <div>
                <Icon width="10" fill={colors.green} />
              </div>
              <Label>
                <FormattedMessage
                  id="sectionprice.feat4"
                  defaultMessage="Unlimited registrations"
                />
              </Label>
            </Item>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Item>
              <div>
                <Icon width="10" fill={colors.green} />
              </div>
              <Label>
                <FormattedMessage
                  id="sectionprice.feat5"
                  defaultMessage="Fully responsive"
                />
              </Label>
            </Item>
            <Item>
              <div>
                <Icon width="10" fill={colors.green} />
              </div>
              <Label>
                <FormattedMessage
                  id="sectionprice.feat6"
                  defaultMessage="Technical maintenance"
                />
              </Label>
            </Item>
            <Item>
              <div>
                <Icon width="10" fill={colors.green} />
              </div>
              <Label>
                <FormattedMessage
                  id="sectionprice.feat7"
                  defaultMessage="Assistance"
                />
              </Label>
            </Item>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={5} md={5} lg={5}>
        <Card>
          <Price>
            800 <span>€</span>
          </Price>
          <Month>
            <FormattedMessage id="sectionprice.period" defaultMessage="Month (in annual prepayment)" />
          </Month>
          <Setup>
            <FormattedMessage
              id="sectionprice.setup"
              defaultMessage="Not setup cost"
            />
          </Setup>
          <Desc>
            <FormattedMessage
              id="sectionprice.legend"
              defaultMessage="Deal platform is a pay-as-you-go solution ready in no time !"
            />
          </Desc>
          <a href="mailto:info@igloo.be">
            <Button>
              <FormattedMessage
                id="sectionprice.button"
                defaultMessage="Request a demo"
              />
            </Button>
          </a>
        </Card>
      </Col>
    </Row>
  </Layout>
);
