import React from "react";
import styled from "styled-components";
import { colors } from "../lib/constant";
import { fontFamily, fontSize, product_sans } from "../lib/fonts";
import { mediaQuery } from "react-styled-mediaquery";
import { Media } from "../lib/media";
import { DottedLink } from "./ui/DottedLink";
import { Layout } from "./ui/Layout";
import { FormattedMessage } from "react-intl";

const heart = require("../../assets/heart.svg");
const logoFooter = require("../../assets/logo-footer.svg");

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-top: 1px solid ${colors.greyLt};
  ${fontSize("12px")};
  ${fontFamily(product_sans.medium)};
  margin-top: 100px;

  ${mediaQuery("<=", "tablet")`
    margin-top: 50px;
  `}

  ${mediaQuery("<=", "phablet")`
    flex-direction: column;
    margin-top: 20px;
  `}
`;

const FooterLogo = styled(Media)`
  width: 94px;
  min-width: 85px;
`;

const FooterList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
`;

const FooterListLink = styled.a`
  color: ${colors.greyFooter};
  margin: 0 20px;
  transition: color 0.2s;
  ${fontFamily(product_sans.regular)}

  &:hover {
    color: ${colors.primary};
  }
`;

const MadeByContainer = styled.div`
  color: ${colors.greyDrk};
  ${fontFamily(product_sans.regular)}
  ${fontSize("12px")};
  line-height: 15px;
`;

const IconHeart = styled.div`
  width: 16px;
  margin: 0 7px;
  display: inline-block;
`;

const IglooLink = styled.a`
  ${fontSize("12px")};
  margin-left: 5px;
  display: inline-block;
  line-height: 15px;

  ${DottedLink} {
    line-height: 15px;
    ${fontSize("12px")};
    border-bottom: none;
  }
`;

const TextBy = styled.span`
  color: ${colors.grey};
`;

const Clixray = styled.div`
  margin-left: 10px;
  padding-left: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 6px;
    background: #edeef2;
    border-radius: 50%;
    width: 3px;
    height: 3px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  ${mediaQuery("<=", "phablet")`
    margin-top: 25px;
  `}
`;

export const SectionFooter = () => {
  return (
    <>
      <Layout>
        <FooterContainer>
          <FooterLogo when=">768">
            <img src={logoFooter} width="118" alt="Deal platform" />
          </FooterLogo>

          <FooterList>
            <li>
              <FooterListLink
                href="https://igloo.be/iGLOO_privacy.pdf"
                rel="noopener"
                target="_blank"
              >
                <FormattedMessage
                  id="footer.privacy"
                  defaultMessage="Privacy policy"
                />
              </FooterListLink>
            </li>
            <li>
              <FooterListLink
                href="https://igloo.be/iGLOO_disclaimer.pdf"
                rel="noopener"
                target="_blank"
              >
                <FormattedMessage
                  id="footer.disclaimer"
                  defaultMessage="Disclaimer"
                />
              </FooterListLink>
            </li>
            <li>
              <FooterListLink
                href="https://igloo.be/iGLOO_cookies.pdf"
                rel="noopener"
                target="_blank"
              >
                <FormattedMessage
                  id="footer.cookies"
                  defaultMessage="Cookies"
                />
              </FooterListLink>
            </li>
          </FooterList>

          <Info>
            <MadeByContainer>
              <FormattedMessage
                id="footer.madeWidth"
                defaultMessage="Made with"
              />
              <IconHeart>
                <img src={heart} alt="icon heart" />
              </IconHeart>
              <TextBy>
                <FormattedMessage id="footer.by" defaultMessage="by" />
              </TextBy>
              <IglooLink
                href="https://igloo.be/"
                target="_blank"
                rel="noopener"
              >
                <DottedLink fontsize={"12px"}>Igloo</DottedLink>
              </IglooLink>
            </MadeByContainer>
            <Clixray>
              <FormattedMessage
                id="footer.worksWith"
                defaultMessage="Works with"
              />
              <IglooLink
                href="https://www.meetclixray.com"
                target="_blank"
                rel="noopener"
              >
                <DottedLink fontsize={"12px"}>Clixray</DottedLink>
              </IglooLink>
            </Clixray>
          </Info>
        </FooterContainer>
      </Layout>
    </>
  );
};
