import styled, { css } from "styled-components";

type MediaType = "<=412" | ">412" | "<=768" | ">768" | "<=960" | ">960";

export const Media = styled.div<{ when: MediaType | MediaType[] }>`
  display: none;

  ${props => {
    const when = Array.isArray(props.when) ? props.when : [props.when];
    return when.map(value => {
      switch (value) {
        case "<=412":
          return css`
            /* MOBILE: en dessous et egal à 412  */
            @media only screen and (max-width: 412px) {
              display: block;
            }
          `;
        case ">412":
          return css`
            /* MOBILE: en dessus et egal à 412  */
            @media only screen and (min-width: 413px) {
              display: block;
            }
          `;
        case "<=768":
          return css`
            /*TABLET:  en dessous et egal à 768 */
            @media only screen and (max-width: 768px) {
              display: block;
            }
          `;
        case ">768":
          return css`
            /*TABLET:  au dessus de 769 */
            @media only screen and (min-width: 769px) {
              display: block;
            }
          `;
        case "<=960":
          return css`
            /*DESKTOP SM:  en dessous et egal à 960 (pour afficher le menu pas trop tard) */
            @media only screen and (max-width: 960px) {
              display: block;
            }
          `;
        case ">960":
          return css`
            /* DESKTOP: au dessus de 960 */
            @media only screen and (min-width: 961px) {
              display: block;
            }
          `;
      }
    });
  }}
`;
