import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { Title } from "./ui/Title";
import { Paragraph } from "./ui/Paragraph";
import { mediaQuery } from "react-styled-mediaquery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ArrowLink } from "./ui/ArrowLink";
import { Layout } from "./ui/Layout";
import { Link } from "react-scroll";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
  margin-top: 100px;

  ${mediaQuery("<=", "tablet")`
    margin-top: 50px;
  `}
`;

const imgRight = require("../../assets/illustrations/more.svg");

const TitleStyle = styled(Title)`
  line-height: 48px;

  ${mediaQuery("<=", "tablet")`
    line-height: 34px;
  `}
`;

const ImgFoldiobox = styled(LazyLoadImage)`
  width: 100%;
  display: block;
`;

export const SectionMore = () => {
  return (
    <Container>
      <Layout>
        <Row between="xs" middle="xs" id="foldioboxContainer">
          <Col xs={false} sm={5} md={5} lg={5}>
            <ImgFoldiobox
              src={imgRight}
              alt="Image foldiobox"
              effect="opacity"
              threshold={300}
            />
          </Col>
          <Col
            xs={12}
            smOffset={1}
            sm={6}
            mdOffset={1}
            md={6}
            lgOffset={1}
            lg={6}
          >
            <TitleStyle htmlLevel={2} theme="titleBodyStyle">
              <FormattedMessage
                id="sectionmore.title"
                defaultMessage="And more ..."
              />
            </TitleStyle>
            <Paragraph className="foldioBox">
              <FormattedMessage
                id="sectionmore.desc"
                defaultMessage=" We support a lot of features such as Secure codes to avoid
                    multiple registration, referral code to track/reward the source of
                    subscription, easy login for face-to-face registrations, Opt-in's,
                    Interests, Place of purchase preferences, Online partners,
                    Client-of relations,... and so much more... Let's talk if you want
                    to have some more !"
              />
            </Paragraph>
            <Link href="#" to="contact" smooth={true} duration={700}>
              <ArrowLink justifyContent="start" marginTop="30px">
                <FormattedMessage
                  id="sectionmore.contact"
                  defaultMessage="Contact us"
                />
              </ArrowLink>
            </Link>
          </Col>
        </Row>
      </Layout>
    </Container>
  );
};
