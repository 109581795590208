import React, { Fragment, ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../lib/constant";
import { product_sans, fontFamily, fontSize } from "../lib/fonts";
import { mediaQuery } from "react-styled-mediaquery";
import { Media } from "../lib/media";
import { Col, Row } from "react-styled-flexboxgrid";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormattedMessage } from "react-intl";
interface LiProps {
  active?: boolean;
}

interface IFeatureListItem {
  label: React.ReactNode;
  active: boolean;
  desc?: React.ReactNode;
  imageUrl?: any;
}

const FeatureListContainer = styled.ul`
  margin: 0;
  padding: 0;
`;

const FeatureListlink = styled.span<LiProps>`
  padding: 15px 30px;
  ${fontFamily(product_sans.medium)};
  color: ${(props) => (props.active ? `${colors.greyDrk}` : `${colors.grey}`)};
  ${fontSize("24px")}
  display: block;
  cursor: pointer;
  transition: color 0.2s;

  ${mediaQuery("<=", "tablet")`
    padding: 20px;
    ${fontSize("20px")}
  `}
`;

const FeatureList = styled.li<LiProps>`
  border-left: ${(props) =>
    props.active ? `3px solid ${colors.action}` : `1px solid ${colors.greyLt}`};
  transition: border-left 0.2s;

  &:not(:first-child) {
    margin-top: 15px;
  }

  &:hover {
    border-left: 3px solid ${colors.secondary};
  }

  &:hover ${FeatureListlink} {
    color: ${colors.greyDrk};
  }
`;

const DescriptionText = styled.div`
  color: ${colors.greyDrk};
  padding-left: 30px;
  ${fontSize("14px")};
  line-height: 22px;
  border-left: 1px solid ${colors.greyLt};

  ${mediaQuery("<=", "tablet")`
  border-left: 1px solid transparent;
  `}
`;

const ImageContainer = styled(Media)`
  position: relative;
  width: 100%;
  height: 554px;
`;

const FeatureListImage = styled(LazyLoadImage)`
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  display: block;
`;

const FeatureListImageMobile = styled(FeatureListImage)`
  margin: 15px 0;
`;

const ListImageAnimation = ({
  visible,
  src,
  alt,
  ...rest
}: {
  visible: boolean;
  src?: any;
  alt: string;
}) => (
  <motion.div
    style={{ position: "absolute", width: "100%", height: "100%" }}
    animate={visible ? { opacity: 1 } : { opacity: 0 }}
    initial={false}
    {...rest}
  >
    <FeatureListImage src={src} alt={alt} visibleByDefault={true} />
  </motion.div>
);

const ListTextAnimation = ({
  visible,
  ...rest
}: {
  visible: boolean;
  children: ReactNode;
}) => (
  <motion.div
    style={{ overflow: "hidden" }}
    animate={
      visible ? { height: "auto", opacity: 1 } : { height: 0, opacity: 0 }
    }
    initial={false}
    {...rest}
  />
);

export const SectionFeaturesList = ({
  items,
  onItemClick,
}: {
  items: IFeatureListItem[];
  onItemClick: (i: number) => void;
}) => {
  return (
    <>
      <Row bottom="xs" id="features">
        <Col xs={12} sm={5} md={5} lg={5}>
          <FeatureListContainer>
            {items.map((item, i) => (
              <Fragment key={i}>
                <FeatureList active={item.active}>
                  <FeatureListlink
                    active={item.active}
                    onClick={(e) => {
                      e.preventDefault();
                      onItemClick(i);
                    }}
                  >
                    {item.label}
                  </FeatureListlink>
                </FeatureList>
                <ListTextAnimation visible={item.active}>
                  <DescriptionText>{item.desc}</DescriptionText>
                  <Media when={"<=768"}>
                    <FeatureListImageMobile
                      src={item.imageUrl}
                      alt=""
                      visibleByDefault={true}
                    />
                  </Media>
                </ListTextAnimation>
              </Fragment>
            ))}
            <FeatureList>
              <Link
                to="foldioboxContainer"
                spy={true}
                smooth={true}
                duration={700}
                offset={-150}
              >
                <FeatureListlink>
                  <FormattedMessage
                    id="features.more"
                    defaultMessage="And more"
                  />
                </FeatureListlink>
              </Link>
            </FeatureList>
          </FeatureListContainer>
        </Col>

        <Col
          xs={false}
          smOffset={1}
          mdOffset={1}
          lgOffset={1}
          sm={6}
          md={6}
          lg={6}
        >
          <ImageContainer when={">768"}>
            {items.map((item, i) => (
              <ListImageAnimation
                key={i}
                visible={item.active}
                src={item.imageUrl}
                alt=""
              />
            ))}
          </ImageContainer>
        </Col>
      </Row>
    </>
  );
};
