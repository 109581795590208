import React from "react";
import { Header } from "../components/Header";
import { SectionFeatures } from "../components/SectionFeatures";
import { GlobalStyles } from "../components/ui/GlobalStyles";
import { SectionPrice } from "../components/SectionPrice";
import { SectionContact } from "../components/SectionContact";
import { SectionFooter } from "../components/SectionFooter";
import { Helmet } from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../lib/constant";
import { mediaQuery } from "react-styled-mediaquery";
import { CardDevices } from "../components/ui/CardDevices";
import { Separator } from "../components/ui/Separator";
import { fontSize } from "../lib/fonts";
import { FormattedMessage } from "react-intl";
import { SectionMore } from "../components/SectionMore";

const Desc = styled.div`
  ${fontSize("15px")};
  line-height: 25px;
  padding-top: 10px;
  padding-bottom: 30px;

  ${mediaQuery("<=", "tablet")`margin-bottom: 30px;`}
`;

const Index = () => {
  return (
    <>
      <Helmet>
        <html lang="fr" />
        <title>
          Deal platform, Refunds & couponing front-end straight from your CRM.
        </title>
        <meta
          name="title"
          content="Deal platform, Refunds & couponing front-end straight from your CRM."
        />
        <meta
          name="keywords"
          content="Deal platform, Refunds & couponing front-end straight from your CRM.."
        />
        <meta
          name="description"
          content="Deal platform is the easiest solution to display intuitive maps on your websites & campaigns, connected in real-time with best in class mapping system Google Maps and your Clixray CRM."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        ></meta>
        <meta name="application-name" content="Deal platform" />
        <meta name="author" content="Igloo" />
      </Helmet>

      <GlobalStyles />

      <ThemeProvider theme={theme}>
        <Header />
        <CardDevices />
        <SectionFeatures
          items={[
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.types.title"
                      defaultMessage="All type of deals"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.types.desc"
                    defaultMessage="From coupon deals for rebates, to present deals for free goodies, all the way to cashback deals for refunds via your partners's network."
                  />
                </Desc>
              ),
              imageUrl: require("../../assets/screens/types.png"),
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.cashback.title"
                      defaultMessage="Direct cashback"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.cashback.desc"
                    defaultMessage="We support direct cashback deals which allows to refund your customer straight to their bank account."
                  />
                </Desc>
              ),
              imageUrl: require("../../assets/screens/cashback.png"),
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.theme.title"
                      defaultMessage="Theme customisation"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.theme.desc"
                    defaultMessage="You can set up several themes for a customised branding of each campaign."
                  />
                </Desc>
              ),
              imageUrl: require("../../assets/screens/theme.png"),
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.personalizedContent.title"
                      defaultMessage="Personalized content"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.personalizedContent.desc"
                    defaultMessage="Every text is customizable on a global layer and a specific layer... So your campaign is 100% personalized per campaign and per language."
                  />
                </Desc>
              ),
              imageUrl: require("../../assets/screens/content.png"),
            },
            {
              label: (
                <div>
                  <span>
                    <FormattedMessage
                      id="features.subscription.title"
                      defaultMessage="Subscription flow"
                    />
                  </span>
                </div>
              ),
              desc: (
                <Desc>
                  <FormattedMessage
                    id="features.subscription.desc"
                    defaultMessage="Define and customize each step of the flow... so your campaign is only about what matters !"
                  />
                </Desc>
              ),
              imageUrl: require("../../assets/screens/subscription.png"),
            },
          ]}
        />

        <SectionMore />

        <Separator id="options" />

        <SectionPrice />

        <SectionContact />

        <SectionFooter />
      </ThemeProvider>
    </>
  );
};

export default Index;
