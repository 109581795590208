import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-styled-flexboxgrid";
import { Layout } from "./ui/Layout";
import { Button } from "./ui/Button";
import { colors } from "../lib/constant";
import { IconArrowR16 } from "../icons-ts";
import { fontSize } from "../lib/fonts";
import { mediaQuery } from "react-styled-mediaquery";
import { Link } from "react-scroll";
import { Title } from "./ui/Title";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormattedMessage } from "react-intl";
import { SelectMobile } from "./ui/Select";
import { useLocale } from "../lib/MainProvider";
import { languages } from "./HeaderNavigation";

const logo = require("../../assets/logo.svg");
const illustrationHeader = require("../../assets/illustrations/header.png");

const Container = styled(Layout)`
  padding-top: 60px;

  ${mediaQuery("<=", "desktop")`
    padding-top: 30px;
  `}
`;

const IntroContainer = styled(Col)`
  color: #fff;

  ${mediaQuery("<=", "tablet")`
    text-align: center;
  `}
`;

const HeaderParagraph = styled.div`
  color: ${colors.greyDrk};
  margin-bottom: 40px;
  ${fontSize("15px")};
  line-height: 30px;

  ${mediaQuery("<=", "phablet")`
    display: none;
  `}
`;

const ImgIntro = styled(LazyLoadImage)`
  width: 100%;
  display: block;
`;

const HeaderMobile = styled.div`
  justify-content: space-between;
  align-items: center;
  display: none;
  margin-bottom: 30px;

  ${mediaQuery("<=", "tablet")`
    display: flex;
  `}
`;

export const HeaderIntro = () => {
  const { locale, setLocale } = useLocale();

  return (
    <Container>
      <Row middle="xs" between="xs">
        <Col xs={false} sm={false} md={6} lg={6}>
          <ImgIntro
            width="490"
            src={illustrationHeader}
            alt="illustration"
            visibleByDefault={true}
          />
        </Col>
        <IntroContainer xs={12} md={5} lg={5}>
          <HeaderMobile>
            <img src={logo} alt="Deal platform" height="50" />
            {typeof window !== "undefined" && (
              <SelectMobile
                items={languages}
                value={locale}
                onChange={(e) => {
                  setLocale(e.currentTarget.value as any);
                }}
              />
            )}
          </HeaderMobile>

          <Title htmlLevel={1} theme="titleHeaderStyle">
            <FormattedMessage
              id="intro.title"
              defaultMessage="Refunds & couponing front-end straight from your CRM."
            />
          </Title>

          <HeaderParagraph>
            <FormattedMessage
              id="intro.desc"
              defaultMessage="Deal platform is a flexible solution to display nice and clean interfaces for your couponing & refunds campaigns. Connected in real-time with your Clixray CRM, you can set up your next campaigns in just a few clicks !"
            />
          </HeaderParagraph>

          <Link
            href="#"
            to="features"
            smooth={true}
            duration={700}
            offset={-100}
          >
            <Button icon={<IconArrowR16 width="16" fill={colors.primary} />}>
              <FormattedMessage
                id="intro.buttonLabel"
                defaultMessage="Learn more"
              />
            </Button>
          </Link>
        </IntroContainer>
      </Row>
    </Container>
  );
};
