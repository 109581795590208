import React, { ReactNode } from "react";
import styled from "styled-components";
import { colorThemes } from "../../lib/constant";

type ITheme = "titleHeaderStyle" | "titleBodyStyle" | "titleTableStyle";

const resolveTag = ({ htmlLevel }: { htmlLevel?: number }) =>
  htmlLevel
    ? typeof htmlLevel === "number"
      ? `h${htmlLevel}`
      : htmlLevel
    : "div";

export const Title = styled(
  ({
    children,
    htmlLevel,
    theme,
    ...props
  }: {
    children: ReactNode;
    htmlLevel?: number;
    theme?: ITheme;
  }) => React.createElement(resolveTag({ htmlLevel }), props, children),
)`
  ${p => colorThemes[p.theme as keyof typeof colorThemes]}
`;
